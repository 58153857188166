import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { isBrowser } from "../utils/browser"
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import ArticleTemplate from './article'
import AboutPage from './about'
import { linkResolver } from '../utils/link-resolver'

function NotFoundPage() {
  const { allPrismicNotfound } = useStaticQuery(graphql`
    query NotFoundQuery {
      allPrismicNotfound {
        edges {
          node {
            data {
              title {
                html
                text
              }
              body {
                html
                text
              }
              bg_img {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { title, body, bg_img } = allPrismicNotfound.edges[0].node.data

  return (
    isBrowser() && (
      <Layout>
        <SEO title="404: Not found" />
        <div className="bg-grey-light">
          <div className="max-w-body mx-auto px-0">
            <div>
              <div className="max-w-6xl md:w-11/12 md:py-24 md:mx-auto md:flex">
                <div className="md:w-1/2 flex items-center md:pr-16">
                  <div className="p-6 pt-8 md:px-0">
                    <div className="headline-text">{parse(title.html)}</div>
                    <div className="sub-headline-text">{parse(body.html)}</div>
                  </div>
                </div>
                <div className="md:w-1/2 flex items-center">
                  <img alt={bg_img.alt} src={bg_img.url} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      article: ArticleTemplate,
      about: AboutPage,
    }),
  },
])
